<template>
  <v-container class="cabin-container cabin-text">
    <v-overlay absolute  :class="`text-center`" :value="showRegisterPopup && locked" z-index="3">
      <div class="dialog-frame">
        <p class="d-title">Bienvenue Agent Capricorne</p>
        <p class="d-text">Entrez votre adresse mail puis indiquez votre réponse.</p>
        <p>
          <input
                    type="email"
                    v-model="email"
                    @keypress.enter="hideRegisterPopup()"
                    :class="{ 'email-error': !isEmailValid }"
                />
        </p>
        <p>
          <span :class="{ 'skip-btn': isEmailValid }" @click="hideRegisterPopup()">
            Envoyer
          </span>
        </p>
      </div>
    </v-overlay>
    <v-overlay absolute  :class="`text-center`" :value="showSuccessPopup" z-index="3">
      <div class="dialog-frame">
        <p class="d-title">Bravo Agent Capricorne</p>
        <p class="d-text">Vous venez de valider avec succès notre test de recrutement.<br/>Poursuivez votre enquête en cliquant sur ce lien : </p>
        <p>
          <a target="_blank" href="https://dossierscriminels.com/collections/dossiers/products/dossiers-criminels-disparition-aux-caraibes">Disparition aux caraïbes</a>
        </p>
        <p>
          <span class="skip-btn" @click="showSuccessPopup = false">
            Fermer
          </span>
        </p>        
      </div>
    </v-overlay>    
    <template v-if="isUnlockAllowed">
      <v-row>
        <v-col
          >{{ $t("colossus.cabins.labels.unlock") }}&nbsp;{{
            $t(`colossus.data.cabins.cabin${cabinID}.title`)
          }}</v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <v-container class="form-container">

            <v-template v-for="f in nameFields" :key="`field-${f}`" class="flex-wrap flex-row" dense>
              <v-row v-if="f === 'lastName'" class="flex-wrap flex-row" dense>
                <v-col cols="auto"
                  >{{ $t("colossus.cabins.labels.lastName") }}:</v-col
                >
                <v-col cols="auto" class="grow">
                  <input
                    autocomplete="garbage"
                    type="text"
                    v-model="form.lastName"
                    class="cabin-input cabin-text"
                    :class="{ inputerror: unlockErrors.lastName }"
                    @keypress.enter="tryOpen"
                /></v-col>
              </v-row>
              <v-row v-if="f === 'firstName'" class="flex-wrap flex-row" dense>
                <v-col cols="auto"
                  >{{ $t("colossus.cabins.labels.firstName") }}:</v-col
                >
                <v-col cols="auto" class="grow">
                  <input
                    type="text"
                    autocomplete="garbage"
                    v-model="form.firstName"
                    class="cabin-input cabin-text"
                    :class="{ inputerror: unlockErrors.firstName }"
                    @keypress.enter="tryOpen"
                  />
                </v-col>
              </v-row>
            </v-template>


            <v-row class="flex-wrap flex-row" dense>
              <v-col cols="auto"
                >{{ $t("colossus.cabins.labels.cabinNumber") }}:</v-col
              >
              <v-col cols="auto" class="grow">
                <input
                  type="text"
                  v-model="form.cabinNumber"
                  class="cabin-input cabin-text"
                  :class="{ inputerror: unlockErrors.cabinNumber }"
                  @keypress.enter="tryOpen"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row v-if="locked">
        <v-col>
          <button @click="tryOpen" class="unlock-button">
            {{ $t("colossus.cabins.buttons.open") }}
          </button>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <div class="unlock-message">
            <span>{{ $t("colossus.cabins.messages.youCanOpen.line1") }}</span>
            <br />
            <span
              >{{ $t("colossus.cabins.messages.youCanOpen.line2") }}&nbsp;{{
                this.cabinID
              }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col> <v-img :src="cabinImg"></v-img> </v-col>
      </v-row>
      <audio :src="unlockSoundUrl" ref="unlockSound" />
    </template>
    <template v-else>
      <v-row>
        <v-col>
          <div class="error-message">
            <span>{{ $t("colossus.cabins.messages.unlockForbidden") }}</span>
          </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import currentGameMixin from "../../mixins/currentGameMixin";
import { collection, addDoc } from "firebase/firestore"; 

export default {
  name: "Event",
  data() {
    return {
      cabinID: 'A',
      publicPath: process.env.BASE_URL,
      form: {
        firstName: "",
        lastName: "",
        cabinNumber: "",
      },
      nameFields: [],
      locked: true,
      unlockErrors: {
        lastName: false,
        firstName: false,
        cabinNumber: false,
      },
      initialized: false,
      showRegisterPopup: true,
      showSuccessPopup: false,
      email: "",
      regexMail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,

    };
  },
  mixins: [currentGameMixin],
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },     
    cabinImg: function () {
      return require(`@/assets/images/${this.caseID}/cabins/porte${
        this.cabinID
      }_${this.locked ? "lock" : "unlock"}.svg`);
    },
    longCabinID: function () {
      return `cabin${this.cabinID}`;
    },
    unlockSoundUrl: function () {
      return `${this.casePublicAudio}/door_unlock.mp3`; // require(`@/assets/audio/${this.caseID}/cabins/door_unlock.mp3`);
    },
    isUnlockAllowed: function () {
      return this.$store.getters.colossusCabinUnlockAllowed(this.longCabinID);
    },
    isEmailValid: function() {
      return this.regexMail.test(this.email)
    },    
  },
  methods: {

    hideRegisterPopup: function() {
      if(!this.isEmailValid)
        return;

      addDoc(collection(this.$firestore, "mailing"), {
        email: this.email,
        source: "Colossus",
        date: new Date()
      })

      this.showRegisterPopup = false
    },
    tryOpen: function () {
      this.$store.dispatch("colossusTryUnlockCabin", {
        cabinID: this.longCabinID,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        cabinNumber: this.form.cabinNumber,
      });
    },
    resetCabinError: function (fieldName) {
      this.$store.dispatch("colossusResetCabinError", {
        cabinID: this.longCabinID,
        field: fieldName,
      });
    },
    resetAllCabinErrors: function () {
      for (let fieldName in this.unlockErrors) {
        this.resetCabinError(fieldName);
      }
    },
    resetForm: function () {
      for (let prop in this.form) this.form[prop] = "";
    },
    playUnlockSound: function () {
      this.$refs.unlockSound.play();
    },
    setFieldOrder: function () {
      if (this.$i18n.t("colossus.cabins.firstNameBeforeLastName") === 'Y') {
        this.nameFields = ['firstName','lastName'];
      } else {
        this.nameFields = ['lastName','firstName'];
      } 
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function () {
        this.resetAllCabinErrors();
      },
    },
    cabinID: function () {
      this.resetCabinError();
      this.locked = this.$store.getters.colossusCabinLocked(this.longCabinID);
      this.resetForm();
    },
    locked: function (newValue) {
      if (this.initialized && !newValue) {
         this.playUnlockSound();
         this.showSuccessPopup = true;
      }
    },
    locale: function () {
      this.setFieldOrder();
    },
  },
  mounted() {
    this.locked = this.$store.getters.colossusCabinLocked(this.longCabinID);
    this.showSuccessPopup = !this.locked;
 
    this.setFieldOrder();

    this.$store.subscribe((mutation) => {
      if (
        mutation.type === "colossusSetCabinError" ||
        mutation.type === "colossusResetCabinError"
      )
        for (let fieldName in this.unlockErrors) {
          this.unlockErrors[fieldName] = this.$store.getters.colossusCabinError(
            { cabinID: this.longCabinID, field: fieldName }
          );
        }

      if (mutation.type === "unlockSection") {
        this.locked = this.$store.getters.colossusCabinLocked(this.longCabinID);
      }

      this.initialized = true;
    });
  },
};
</script>
<style lang="scss" scoped>
.cabin-container {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.cabin-text {
  font-family: Source Sans 3;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.form-container {
  width: 100%;
  max-width: 300px;
}
.cabin-input {
  border: none;
  background: #00000080;
  width: 100%;
}
.cabin-input.inputerror {
  background: #9c000066;
  color: #ff0000;
}
.unlock-button {
  font-family: "Bebas Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1pt solid #ffc122;
  color: #ffc122;
  background: #00000000;
}
.unlock-button:hover {
  border: 1px solid #ffffff;
  box-shadow: 3px 4px 3px 0px #00000050;
  color: #ffffff;
}
.unlock-message {
  border: 1px solid #00ff29;
  color: #00ff29;
  padding: 10px;
}

.error-message {
  border: 1px solid #ff2900;
  color: #ff2900;
  padding: 10px;
}

.dialog-frame {
  border-radius: 0px;
  background: rgba(0, 0, 0, 0.8);
  height: 250px;
  width: 350px;
  padding: 0;
  border-radius: 0px !important;
  transition: all 0.25s;

  &::v-deep button {
    margin-top: 0px;

    &.v-btn.v-btn--icon {
      color: #ffc122;
    }
  }

  input {
    border-radius: 3px;
    padding: 5px;
    width: 250px;
  }

  .skip-btn {
    color: #fff;

    &:hover {
      color: #ffc122;      
      cursor: pointer;
    }
  }

  .email-error {
    border: 1px solid #999;
    color: #999;
  }

  .d-title {
    font-family: "Bebas Neue";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.06em;
    text-align: center;
    text-transform: uppercase;
    color: #ffc122;
    margin-top: 20px;
  }  

  .d-text {
    text-align: center;
    color: #fff;
    padding: 20px;
    font-family: "Source Sans 3";
    font-size: 14px;
    margin: 0;
    text-transform: none;
  }
}
</style>