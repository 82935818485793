export default {
    socialNetworks: [
        { id: "instagram", url: "https://www.instagram.com/dossiers_criminels_jeu/" },
        { id: "tiktok", url: "https://www.tiktok.com/@dossiers_criminels_jeu?_t=8YMQxafXoMX&_r=1" }
    ],
    amazon: "https://www.amazon.fr",
    website: "https://dossierscriminels.com/",
    showSubscribeForLocales: [
        "fr"
    ]
}